/* Customize your main colors in :root variables */
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Teko:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@media only screen and (min-width: 1200px) {
:root  .container {
      max-width: 1200px;
  }
}


@media only screen and (max-width: 1366px) {
}


@media only screen and (max-width: 1199px) {

}

.container {
  background: rgba(0,0,0,0.8);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .container {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.video-background {
  --src: "shrine2.mp4"
}

:root {
  --card-backdrop-filter: blur(10px);
  --webkit-backdrop-filter: blur(10px);
  --main-background-color : #343A50;
  --card-background-color: rgba(0,0,0,0.5);
  --countdown-background-color: #433765;
  --main-text-color: #ff1d25;
  --title-text-color: #b7e36a;
  --o-border-image: stretch;
}

body{
/*  background-color:var(--main-background-color);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Bungee', cursive;
  font-size: 1.1em;
  background-color: #1b1a1e;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}

:root .codpPJ {
  position: absolute;
  margin: 5px;
  font-weight: 300;
  font-size: 1.2em !important;
  font-family: 'Bungee',cursive !important;
}

:root .wallet-adapter-button {
  background-color: #1b1a1e;
  border: none;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Bungee','Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
}

:root .wallet-adapter-dropdown-list-item {
  background: #404144;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 300;
  height: 37px;
  color: white;
}

:root .kQqLqx {
  color: black;
  width: auto;
  padding: 5px 5px 5px 16px;
  min-width: 48px;
  min-height: auto;
  border-radius: 22px;
  background-color: var(--main-text-color);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  -webkit-transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
  font-weight: 300;
  line-height: 1.75;
  text-transform: uppercase;
  border: 0;
  margin: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  outline: 0;
  position: relative;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 10px;
}
